import * as React from "react"
import Head from '../components/head'
import Layout from '../components/layout'


// markup
const IndexPage = () => {
  return (

<Layout>
      <Head title="Home"/>
      <h1>Home</h1>
      <p>AreaFour presents artistic works by Mark Webster. You will find visual as well as sound pieces, some of which you can buy directly from the artist by <a href="mailto:mark.webster@wanadoo.fr">contacting him via email.</a> </p>
      <p>Mark Webster creates art and graphic work primarily using custom-made software tools along with computational and generative strategies as his main approach. You can learn more about each work in detail through consulting the Artwork section.</p>

</Layout>
    
  )
}

export default IndexPage
